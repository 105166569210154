// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
@import "jquery.fancybox.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";

// Font-Awesome
@import "font-awesome/font-awesome.scss";

//Slick Slider
@import "slick/slick.scss";
@import "slick/slick-theme.scss";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

/* ==========================================================================
   Project's custom fonts
   ========================================================================== */

@font-face {
  font-family: 'CDUKievit_Normal';
  src: url('../fonts/cdukvtw_-webfont.eot');
  src: url('../fonts/cdukvtw_-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/cdukvtw_-webfont.woff') format('woff'),
  url('../fonts/cdukvtw_-webfont.ttf') format('truetype'),
  url('../fonts/cdukvtw_-webfont.svg#cdu_kievit_tabregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CDUKievit_Bold';
  src: url('../fonts/CDUKievitTab-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CDUKievitTab-Bold.woff') format('woff'),
  url('../fonts/CDUKievitTab-Bold.ttf')  format('truetype'),
  url('../fonts/CDUKievitTab-Bold.svg#CDUKievitTab-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CDUKievit_BoldItalic';
  src: url('../fonts/CDUKievitTab-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CDUKievitTab-BoldItalic.woff') format('woff'),
  url('../fonts/CDUKievitTab-BoldItalic.ttf')  format('truetype'),
  url('../fonts/CDUKievitTab-BoldItalic.svg#CDUKievitTab-BoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CDUKievit_Italic';
  src: url('../fonts/CDUKievitTab-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CDUKievitTab-Italic.woff') format('woff'),
  url('../fonts/CDUKievitTab-Italic.ttf')  format('truetype'),
  url('../fonts/CDUKievitTab-Italic.svg#CDUKievitTab-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CDUSkopex_BoldItalic';
  src: url('../fonts/cduskopexbolditalic-webfont.eot');
  src: url('../fonts/cduskopexbolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/cduskopexbolditalic-webfont.woff') format('woff'),
  url('../fonts/cduskopexbolditalic-webfont.ttf') format('truetype'),
  url('../fonts/cduskopexbolditalic-webfont.svg#cdu_skopexbold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CDUSkopex_Italic';
  src: url('../fonts/cduskopexregularitalic-webfont.eot');
  src: url('../fonts/cduskopexregularitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/cduskopexregularitalic-webfont.woff') format('woff'),
  url('../fonts/cduskopexregularitalic-webfont.ttf') format('truetype'),
  url('../fonts/cduskopexregularitalic-webfont.svg#cdu_skopexitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* ==========================================================================
   Project's custom styles
   ========================================================================== */

#content ol {
  padding: 0 20px;
}

#content ol li:before {
  content: none;
}

.btn {
  line-height: 1.42857143; //fixes SASS number precision issue
}

$brand-height: 52px; //Höhe der Logografik
$brand-padding-vertical: (($navbar-height - $brand-height) / 2);

.navbar-brand {
  padding: 0!important;
}

.container-fluid {
  max-width: 1000px + $grid-gutter-width;
}

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

#content {
  position: relative;
}

#content p {
  margin: 0;
}

#content img {
  max-width: 100%;
}

body {
  font-family: CDUKievit_Normal, Arial, sans-serif;
  @include font-size(1.4);
  color: #3e3e3e;
}

h1, h2, h3, h4 {
  margin-top: 0;
  color: #3e3e3e;
}

h1 {
  font-family: CDUKievit_BoldItalic, Arial, sans-serif;
  @include font-size(2.0);
  color: $cdu-orange;
}

h2 {
  font-family: CDUKievit_BoldItalic, Arial, sans-serif;
  @include font-size(1.8);
  color: $cdu-orange;
}

h3 {
  font-family: CDUKievit_BoldItalic, Arial, sans-serif;
  @include font-size(1.8);
}

h4 {
  font-family: CDUKievit_BoldItalic, Arial, sans-serif;
  @include font-size(1.4);
}

p {
  font-family: CDUKievit_Normal, Arial, sans-serif;
  @include font-size(1.4);
  color: #3e3e3e;
  margin: 0;
}

a.list-group-item {
  margin-bottom: 20px;
  display: block;
}

h3.list-group-item-heading {
  margin-bottom: 2px;
}

.keine-meldungen {
  font-family: CDUKievit_BoldItalic, Arial, sans-serif;
  @include font-size(1.6);
  color: #fff;
  margin: 25px 0 0 0;
  display: block;
  font-size: 16px!important;
  font-weight: normal;
}

.navbar {
  height: 60px;
  border-bottom: 3px solid $cdu-orange;
}

span.icon-bar {
  position: relative;
  opacity: 0;
  transition: all 0.5s;
}

span.icon-bar.first {
  transform: rotate(45deg);
  top: 10px;
  opacity: 1!important;
  transition: all 0.5s;
}

span.icon-bar.last {
  transform: rotate(-45deg);
  top: -10px;
  opacity: 1!important;
  transition: all 0.5s;
}

.navbar-toggle.collapsed span.icon-bar {
  position: relative;
  opacity: 1;
  transition: all 0.5s;
}

.navbar-toggle.collapsed span.icon-bar.first {
  transform: rotate(0deg);
  top: 0;
  opacity: 1!important;
  transition: all 0.5s;
}

.navbar-toggle.collapsed span.icon-bar.last {
  transform: rotate(0deg);
  top: 0;
  opacity: 1!important;
  transition: all 0.5s;
}

.navbar-toggle {
  float: left;
  background-color: transparent;
  border: none;
  margin-top: 8px;
  margin-bottom: 8px;
}

.navbar-toggle:focus,
.navbar-toggle:hover {
  background-color: transparent!important;
}

.navbar-toggle .icon-bar {
  background-color: $cdu-orange!important;
  width: 35px;
  height: 5px;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 5px;
}

.navbar-brand {
  float: right;
  margin-right: 10px;
}

.navbar-brand img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  float: right;
}

.affix {
  top: 0;
}

.logo-text {
  font-family: CDUKievit, Arial, sans-serif;
  @include font-size(1.0);
  color: #000;
  float:left;
  margin: 24px 15px 0 0;
  text-align: right;
  line-height: 13px;
  letter-spacing: -0.25px;
}

.navbar-collapse {
  background-color: $cdu-orange;
  border: none;
  padding-bottom: 20px;
}

.navbar-collapse .nav.navbar-nav {
  margin: 0 -10px;
}

.dropdown-menu {
  background-color: rgba(255,255,255,0.15)!important;
}

.dropdown-menu li {
  padding-left: 10px;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
  background-color: #e76800;
}

.nav.navbar-nav .active .dropdown-menu a {
  background-color: transparent!important;
}

.dropdown-toggle:after {
  font-family: FontAwesome;
  content:"\f107";
  color: #FFF;
  float: right;
  font-size: 32px;
}

.open .dropdown-toggle:after {
  font-family: FontAwesome;
  content:"\f106";
  color: #FFF;
  float: right;
  font-size: 32px;
}

nav a {
  font-family: CDUKievit_Bold, Arial, sans-serif;
  @include font-size(1.4);
  color: #FFF!important;
  text-transform: uppercase;
  padding: 10px!important;
}

.nav.navbar-nav .active a {
  background-color: #e76800;
}

.nav.navbar-nav li a:hover,
.nav.navbar-nav li a:focus,
.nav.navbar-nav li.active a:hover,
.nav.navbar-nav li.active a:focus {
  background-color: #e76800;
}

.navbar-form {
  border: none!important;
  box-shadow: none;
  margin: 0 -10px;
}

.navbar-form .input-group input {
  border: none;
  border-radius: 0;
  height: 36px;
}

.btn.btn-default {
  border: 1px solid #FFF;
  height: 36px;
  border-radius: 0;
  background-color: $cdu-orange;
  color: #FFF;
  padding: 0 8px;

}

.navbar-form .btn.btn-default {
  @include font-size(2.4);
}

.btn.btn-default:hover {
  background-color: #686868;
}

.form-control:focus {
  box-shadow: none;
}

.form-control {
  border-radius: 0!important;
}

.facebook, .twitter {
  font-family: CDUKievit_BoldItalic, Arial, sans-serif;
  position: relative;
  display: block;
  height: 40px;
  color: #FFF;
  border-bottom: 1px solid #FFF;
  text-transform: none;
}

.facebook span, .twitter span {
  @include font-size(1.6);
}

.facebook i, .twitter i {
  position: absolute;
  top: 8px;
  @include font-size(2.4);
}

.facebook i {
  left: 40px;
}

.twitter i {
  left: 38px;
}

.facebook {
  padding: 10px 65px!important;
}

.twitter {
  padding: 10px 70px!important;
}

.start-slider {
  position: relative;
  height: 240px;
  margin-bottom: 0;
}

.slide-item {
  display: block;
  position: relative;
  height: 240px;
  background-size: cover;
  background-position: center;
}

.slide-item .col-xs-12 {
  height: 240px;
}

.start-slider .overlay {
  display: block;
  width: 75%;
  position: absolute;
  right: 10px;
  bottom: -200px;
  text-align: right;
  transition: bottom 0.5s ease 0.4s;
}

.start-slider .slick-active .overlay {
  bottom: 10px;
}

.start-slider .overlay-text {
  font-family: CDUSkopex_BoldItalic, Arial, sans-serif;
  @include font-size(2.0);
  text-shadow: 0 0 5px rgba(0, 0, 0, 1);
  color: #FFF;
}

.start-slider .overlay-img img {
  max-width: 100%;
  float: right;
}

.slick-dots {
  bottom: -35px;
}

.slick-dots li button {
  height: 15px;
  width: 15px;
  padding: 0;
}

.slick-dots li button:before {
  content: none!important;
}

.slick-dots li {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: $cdu-orange;
  opacity: 0.75;
}

.slick-dots li.slick-active {
  opacity: 1;
}

.content-header {
  font-family: CDUKievit_BoldItalic, Arial, sans-serif;
  @include font-size(2.6);
  border-bottom: 1px solid #9f9f9f;
  padding: 10px 0 5px 0;
}

.tag {
  display: inline-block;
  font-family: CDUKievit_Bold, Arial, sans-serif;
  @include font-size(1.2);
  text-align: center;
  padding: 6px 10px;
  border: 1px solid #000;
  color: #000;
}

.tag.red {
  color: $cdu-red;
  border: 1px solid $cdu-red;
}

.tag.orange {
  color: $cdu-orange;
  border: 1px solid $cdu-orange;
}

.news-slider {
  position: relative;
  margin: 0 -10px 0 -10px;
}

.news-slider-item {
  margin: 10px;
  background-color: #FFF;
}

.news-inner {
  display: block;
  padding: 20px 10px;
  border: 1px solid #dfe0e1;
}

.news-inner p {
  margin: 0 0 10px 0!important;
}

.news-slider-item h3 {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.news-slider-outer {
  padding: 50px 0 10px 0;
  background-color: #f5f5f5;
}

.btn-weiterlesen {
  position: relative;
  font-family: CDUKievit_Italic, Arial, sans-serif;
  display: block;
  width: 100%;
  height: 44px;
  background-color: $cdu-orange;
  color: #FFF;
  text-align: center;
  @include font-size(1.6);
}

.btn-weiterlesen:hover,
.btn-weiterlesen:focus,
.btn-weiterlesen:active {
  color: #FFF;
  background-color: $cdu-red;
}

.btn-weiterlesen:hover:before,
.btn-weiterlesen:focus:before,
.btn-weiterlesen:active:before {
  border-bottom: 6px solid $cdu-red;
}

.btn-weiterlesen span {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.btn-weiterlesen:before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  margin-left: -3px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $cdu-orange;
}

.btn-weiterlesen i.fa.fa-angle-right {
  padding-left: 5px;
}

.slick-slide:focus {
  outline: none!important;
}

.slick-next {
  position: absolute;
  top: -25px;
  right: 10px;
  width: 30px;
  height: 30px;
  color: #000;
}

.slick-prev {
  position: absolute;
  top: -25px;
  right: 40px;
  left: auto;
  width: 30px;
  height: 30px;
  color: #000;
}

.slick-prev:before {
  font-family: FontAwesome;
  content:"\f104";
  color: #000;
}

.slick-next:before {
  font-family: FontAwesome;
  content:"\f105";
  color: #000;
}

.aktuelle-termine {
  //background-image: url('../img/pattern.png'), url('../img/bg_image.jpg');
  //background-repeat: repeat, no-repeat;
  //background-size: 3px 3px, cover;
  //background-position: left, center;
  padding: 20px 0 30px 0;
}

.aktuelle-termine .content-header {
  color: #FFF;
  border-bottom: 1px solid #FFF;
  text-shadow: 0 0 5px rgba(0, 0, 0, 1);
  line-height: 32px;
}

.termin-slider {
  padding: 30px 0 0 0;
  margin: 0 -10px 20px -10px;
}

.termin-slider-item {
  background-color: #FFF;
  padding: 12px 10px 4px 10px;
  margin: 0 10px;
}

.termin-headline-link {
  font-family: CDUKievit_BoldItalic, Arial, sans-serif;
  color: #3e3e3e;
  display: block;
  margin-bottom: 5px;
}

.termin-headline-link:hover {
  color: #ef730e;
}

.termin-slider-item p {
  margin: 0 0 10px 0!important;
}

.termin-datum {
  @include font-size(1.8);
  font-family: CDUKievit_BoldItalic, Arial, sans-serif;
  border-bottom: 1px solid #7f7f7f;
  padding-bottom: 10px;
  margin-bottom: 11px;
}

.termin-slider .slick-next:before,
.termin-slider .slick-prev:before {
  color: #FFF;
  text-shadow: 0 0 5px rgba(0, 0, 0, 1);
}

.teaser-start {
  margin: 10px 0;
}

.teaser-block {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 10px 0;
}

.teaser-block img {
  width: 100%;
}

.teaser-block span {
  display: block;
  position: absolute;
  bottom: 6px;
  right: 10px;
  color: #FFF;
}

.teaser-start-outer {
  padding: 30px 0 30px 0;
}

footer {
  background-color: #f0f0f0;
}

.footer-title {
  font-family: CDUKievit_Italic, Arial, sans-serif;
  @include font-size(1.8);
  background-color: #686868;
  height: 36px;
  margin: 0 -10px 10px -10px;
  padding: 6px 10px 0 10px;
  color: #FFF;
}

footer a {
  display: block;
  width: 50%;
  padding: 4px 0;
  color: #3e3e3e;
}

footer ul {
  margin: 0;
  padding: 0;
}

footer ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.social-links {
  margin: 15px -10px 0 -10px;
}

.social-links li {
  float: left;
  width: 50%;
}

.social-links li a i {
  padding-right: 5px;
}

.social-links li a {
  display: block;
  width: 100%;
  margin: 0 auto;
  background-color: #3e3e3e;
  color: #FFF;
  height: 40px;
  padding: 10px 0 0 0;
  @include font-size(1.6);
  text-align: center;
}

.social-links li:first-of-type {
  border-right: 1px solid #f0f0f0;
}

.content-outer {
  padding: 0 0 50px 0;
  background-color: #FFF;
}

.content-teaser {
  position: relative;
  width: 100%;
  margin: 10px 0 0 0;
}

.content-teaser img {
  width: 100%;
}

.pathfinder {
  margin: 20px 0;
}

.pathfinder a {
  @include font-size(1.2);
  color: #000;
}

.pathfinder a:after {
  font-family: FontAwesome;
  content:"\f105";
  padding: 0 5px;
}

.pathfinder a:last-of-type:after {
  content: none;
}

.pathfinder a:last-of-type {
  cursor: default;
  font-weight: bold;
}

.pathfinder a:last-of-type:hover {
  color: #000;
}

.pathfinder a:hover {
  color: $cdu-orange;
}

.content-start {
  padding: 0!important;
}

.contact {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #b5b5b5;
  overflow: hidden;
}

.contact-detail {
  margin-bottom: 15px;
  padding-bottom: 15px;
  //border-bottom: 1px solid #b5b5b5;
  overflow: hidden;
}

.contact-wysiwyg {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #b5b5b5;
  overflow: hidden;
}

.c-position {
  font-family: CDUKievit_Bold, Arial, sans-serif;
  @include font-size(1.8);
  color: $cdu-orange;
  display: block;
}

.c-name {
  font-family: CDUKievit_Bold, Arial, sans-serif;
  @include font-size(1.6);
  display: block;
}

.c-verband {
  font-family: CDUKievit_Bold, Arial, sans-serif;
  @include font-size(1.6);
  display: block;
  color: #FFF;
}

.verband-outer {
  padding: 5px 10px;
  margin-bottom: 15px;
  background-color: $cdu-orange;
  color: #FFF;
}

.btn-primary {
  background-color: $cdu-orange;
  border: none;
  border-radius: 0;
}

.btn-primary:hover {
  background-color: #686868;
}

.logolink-item {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 0 30px 0;
}

.linkimg {
  width: 100%;
  height: 175px;
  padding: 20px;
  border: 1px solid #f0f0f0;
}

.logolink-item img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

.logolink-item .linktext {
  @include font-size(1.8);
  color: #3e3e3e;
}

.mitglied-table {
  width: 100%;
}

.mitglied-table tr {
  border-bottom: 1px solid #b5b5b5;
}

.mitglied-table tr:first-of-type {
  border-bottom: 2px solid #b5b5b5;
}

.mitglied-table tr td {
  padding: 5px 0;
}

.mitglied-table tr td:first-of-type {
  padding-right: 10px;
}

.mobile-margin-bottom {
  margin-bottom: 30px;
}

.mobile-margin-top {
  margin-top: 30px;
}

.radio {
  margin-top: 20px;
  margin-bottom: 30px;
}

select#title_in {
  width: 50%;
}

.navbar-nav .nav1-3 {
  padding: 0 0 0 10px;
  margin-bottom: 10px;
}

.navbar-nav .nav1-3 li {
  list-style-type: none;
}

/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  nav.navbar {
    border-bottom: none;
    background-color: #FFF;
    height: 149px;
  }

  .navbar-header {
    width: 100%;
    height: 104px;
  }

  .navbar-brand {
    height: 104px;
    margin-right: 0;
  }

  .navbar-collapse {
    position: relative;
    background-color: transparent;
  }

  .navbar-brand {
    float: right;
  }

  .nav-outer {
    height: 45px;
    width: 100%;
    background-color: $cdu-orange;
    //overflow: hidden;
  }

  .nav.navbar-nav {
    background-color: $cdu-orange;
    margin: 0!important;
    width: 720px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .nav.navbar-nav li {
    display: inline-block;
  }

  .nav.navbar-nav a {
    @include font-size(1.2);
    height: 45px;
    border-bottom: 2px solid $cdu-orange;
    transition: border 0.2s;
    padding: 13px 10px!important;
  }

  .nav.navbar-nav li.active a {
    box-sizing: border-box;
    background-color: $cdu-orange;
    border-bottom: 2px solid #FFF;
  }

  .nav.navbar-nav li a:hover,
  .nav.navbar-nav li a:focus,
  .nav.navbar-nav li.active a:hover,
  .nav.navbar-nav li.active a:focus {
    background-color: $cdu-orange;
    border-bottom: 2px solid #FFF;
  }

  nav form.navbar-form {
    position: absolute;
    top: -66px;
    left: 240px;
    width: 300px;
  }

  nav form.navbar-form .input-group {
    width: 300px;
  }

  nav form.navbar-form .input-group input {
    border: 1px solid #d6d6d6;
    box-shadow: none;
    height: 32px;
  }

  .btn.btn-default {
    border: none;
    height: 32px;
  }

  .dropdown-toggle:after {
    content: none;
  }

  .open .dropdown-toggle:after {
    content: none;
  }

  .social-media {
    position: absolute;
    left: 0;
    top: -65px;
    width: 200px;
  }

  .facebook, .twitter {
    font-family: CDUKievit_Italic, Arial, sans-serif!important;
    color: #3e3e3e!important;
    @include font-size(1.4);
    height: 30px;
    transition: all 0.2s;
  }

  .facebook:hover, .social-media .twitter:hover {
    color: $cdu-orange!important;
    border-bottom: 1px solid $cdu-orange;
  }

  .social-media .facebook span,
  .social-media .twitter span {
    //font-family: CDUKievit_Italic, Arial, sans-serif!important;
    //color: #3e3e3e!important;
    @include font-size(1.4);
  }

  .social-media .facebook i {
    @include font-size(1.6);
    left: 11px;
    top: 8px;
  }

  .social-media .twitter i {
    @include font-size(1.6);
    left: 15px;
    top: 8px;
  }

  .facebook {
    padding: 6px 0 0 30px!important;
  }

  .twitter {
    padding: 6px 0 0 38px!important;
  }

  .start-slider {
    height: 300px;
  }

  .slide-item {
    height: 300px;
  }

  .slide-item .col-xs-12 {
    height: 300px;
  }

  .start-slider .overlay {
    width: 50%;
  }

  .start-slider .slick-active .overlay {
    bottom: 25px;
  }

  .start-slider .overlay-text {
    @include font-size(3.2);
  }

  .slick-dots {
    bottom: 0;
  }

  .slick-dots li {
    background-color: #FFF;
  }

  .news-slider-outer {
    padding: 30px 0 45px 0;
    background-color: #f5f5f5;
  }

  .news-slider-item h3 {
    @include font-size(1.6);
  }

  .aktuelle-termine {
    padding: 35px 0 78px 0;
    background-size: 4px 4px,cover;
  }

  .teaser-start-outer {
    padding: 50px 0 0 0;
  }

  .teaser-start {
    margin: 0 0 50px 0;
  }

  .teaser-block span {
    @include font-size(1.2);
    bottom: 5px;
  }

  .footer-header {
    background-color: #686868;
    height: 36px;
    width: 100%;
    margin-bottom: 25px;
  }

  .footer-header .footer-title {
    margin-bottom: 0px;
  }

  footer {
    padding-bottom: 30px;
  }

  footer a {
    display: inline-block;
    padding: 2px 0;
    @include font-size(1.3);
  }

  footer a:hover {
    color: $cdu-orange;
  }

  footer p {
    @include font-size(1.3);
  }

  footer ul {
    margin-bottom: 20px;
  }

  .nuetzliches a {
    font-weight: bold;
  }

  .btn-weiterlesen:hover span {
    color: #FFF;
  }

  .social-links {
    width: auto;
    margin: 0;
  }

  .social-links li {
    width: auto;
    border: none;
    float: none;
  }

  .social-links li a {
    display: inline-block;
    background-color: transparent;
    color: #3e3e3e;
    @include font-size(1.3);
    height: auto;
    padding: 2px 0;
    font-weight: bold;
    text-align: left;
  }

  .social-links li a:hover {
    color: $cdu-orange;
  }

  .social-links li a i {
    padding: 0 2px 0 0;
  }

  .background-header {
    width: 100%;
    height: 155px;
    background-image: url('../img/header-img.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .content-wrapper {
    margin-top: -125px;
  }

  .content-teaser {
    margin: 0;
    border: 5px solid #FFF;
  }

  .pathfinder {
    margin: 8px 0 15px 5px;
  }

  #content {
    padding: 15px;
  }

  .nav-left {
    margin: 0 0 56px 0;
  }

  .nav1-1 {
    margin: 0;
    padding: 0;
  }

  .nav1-1 li {
    padding: 0;
    margin: 0 0 2px 0;
    list-style-type: none;
  }

  .nav1-1 li a {
    font-family: CDUKievit_BoldItalic, Arial, sans-serif;
    @include font-size(1.6);
    color: #2b2b2b!important;
    display: block;
    background-color: #f0f0f0;
    text-transform: none!important;
    border-left: 10px solid #f0f0f0;
    padding: 9px 5px!important;
  }

  .nav1-1 li a:hover {
    border-left: 10px solid $cdu-orange;
  }

  .nav1-1 li a.active {
    border-left: 10px solid $cdu-orange;
  }

  .nav1-2 {
    margin: 3px 0 10px 15px;
    padding: 0;
  }

  .nav1-3 {
    padding: 0 0 0 15px;
  }

  .nav1-2 li {
    margin: 0 0 1px 0;
  }

  .nav1-2 li a {
    font-family: CDUKievit_Italic, Arial, sans-serif;
    @include font-size(1.4);
    padding: 6px 5px 5px 5px!important;
  }

  .nav1-2 li a.active {
    background-color: $cdu-orange;
    color: #FFF!important;
  }

  .nav1-2 li a:hover {
    background-color: $cdu-orange;
    color: #FFF!important;
  }

  .twitter-tl iframe {
    border-left: 1px solid #eaeff2!important;
    border-right: 1px solid #eaeff2!important;
  }

  .twitter-tl-outer {
    margin-bottom: 50px;
  }

  .twitter-tl-outer span {
    font-family: CDUKievit_BoldItalic, Arial, sans-serif;
    @include font-size(1.6);
    border-bottom: 1px solid #b5b5b5;
    display: block;
    padding: 0 4px 6px 1px;
    margin: 0 0 29px 0;
  }

  .twitter-tl-outer i.fa-twitter {
    float: right;
    @include font-size(1.6);
    margin-top: 2px;
  }

  .logo-text {
    margin: 47px 15px 0 0;
  }

  .contact {
    height: 155px;
  }

  .contact-detail {
    height: 235px;
  }

  .contact .img-outer {
    height: 140px;
    overflow: hidden;
  }

  .linkimg {
    height: 175px;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  .container {
    width: 1000px;
  }

  .nav.navbar-nav {
    width: 1060px;
    margin: 0 -40px!important
  }

  .nav.navbar-nav a {
    @include font-size(1.4);
  }

  .news-slider-item h3 {
    @include font-size(1.8);
  }

  nav form.navbar-form {
    left: 395px;
    width: 350px;
  }

  nav form.navbar-form .input-group {
    width: 350px;
  }

  .btn.btn-default {
    font-family: CDUKievit_BoldItalic, Arial, sans-serif;

  }

  .navbar-form .btn.btn-default {
    @include font-size(1.6);
    width: 66px;
  }

  .start-slider {
    height: 320px;
  }

  .slide-item {
    height: 320px;
  }

  .slide-item .col-xs-12 {
    height: 320px;
  }

  .start-slider .overlay {
    width: 45%;
  }

  .news-slider {
    margin: 29px -10px 0 -10px;
  }

  .news-slider .slick-next, .news-slider .slick-prev {
    top: -51px;
  }

  .teaser-start-outer {
    padding: 70px 0 20px 0;
  }

  .teaser-block {
    position: relative;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0);
    top: 0;
    transition: all 0.2s;
  }

  .teaser-block:hover {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.75);
    top: -2px;
  }

  .contact {
    height: 140px;
  }

  .contact .img-outer {
    height: 125px;
    overflow: hidden;
  }

  .mobile-margin-bottom {
    margin-bottom: 0;
  }

  .mobile-margin-top {
    margin-top: 0;
  }

  .social-media {
    width: 350px;
  }
  
}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .container {
    width: 1020px;
  }

  .start-slider {
    height: 430px;
  }

  .slide-item {
    height: 430px;
  }

  .slide-item .col-xs-12 {
    height: 430px;
  }

  .start-slider .overlay {
    width: 490px;
  }

  .start-slider .overlay-img {
    height: 95px;
  }

  .start-slider .overlay-text {
    display: block;
    margin-top: 10px;
    @include font-size(4.0);
  }

  .start-slider .slick-active .overlay {
    bottom: 53px;
  }

  nav form.navbar-form {
    left: 400px;
    width: 275px;
  }

  .navbar-form .input-group {
    width: 341px!important;
  }

  .navbar-form .input-group input {
    width: 275px!important;
  }

  .navbar-form .input-group .input-group-btn {
    width: 66px;
  }

  .news-slider .btn-weiterlesen {
    opacity: 0;
    transition: all 0.3s;
  }

  .news-slider-outer {
    padding: 47px 0 75px 0;
  }

  .content-header {
    padding: 10px 0 7px 0;
  }

  .news-slider-item {
    background-color: transparent;
    transition: all 0.3s;
  }

  .news-inner {
    border: 1px solid transparent;
    transition: all 0.3s;
    padding: 17px 14px;
  }

  .news-slider-item:hover .btn-weiterlesen {
    opacity: 1;
  }

  .news-slider-item:hover {
    background-color: #FFF;
  }

  .news-slider-item:hover .news-inner {
    border: 1px solid #dfe0e1;
  }

  .btn-weiterlesen:hover,
  .btn-weiterlesen:focus,
  .btn-weiterlesen:active {
    color: #FFF;
    background-color: $cdu-orange;
  }

  .btn-weiterlesen:hover:before,
  .btn-weiterlesen:focus:before,
  .btn-weiterlesen:active:before {
    border-bottom: 6px solid $cdu-orange;
  }

  .slick-dots {
    bottom: 8px;
  }

  .slick-dots li {
    width: 12px;
    height: 12px;
  }

  .slick-dots li button {
    height: 12px;
    width: 12px;
    padding: 0;
  }

  .teaser-start-outer {
    padding: 90px 0 23px 0;
  }

  .teaser-start {
    margin: 0 0 60px 0;
  }

  footer p {
    margin: 0 0 7px 0;
  }

  footer a {
    padding: 0;
  }

  footer ul {
    margin-bottom: 14px;
  }

  .twitter {
    margin: 0 0 0 4px;
  }

}