/* ==========================================================================
   Bootstrap variables with custom values
   ========================================================================== */

// Container sizes
// --------------------------------------------------

// Grid-Gutter
$grid-gutter-width: 20px;

$container-large-desktop:      ((1000px + $grid-gutter-width));
$container-lg:                 $container-large-desktop;



// Use following rule to disable responsiveness:
/*
.container {
	width: ((1000px + $grid-gutter-width))!important;
	max-width: none!important;
}
*/


// Basic Color styles
// --------------------------------------------------
$brand-primary: #ef730e;

$link-color: $brand-primary;
//$link-hover-color: ;
$link-hover-decoration: none;



//** Border color for inputs on focus
$input-border-focus:             $brand-primary;



//Glyphicons Font Path
$icon-font-path: "../fonts/";


//== Components
//
//## common border radius sizes
//$border-radius-base: ;
//$border-radius-large: ;
//$border-radius-small: ;



// Basic Navigation styles
// --------------------------------------------------
$navbar-height:                            60px;
$navbar-margin-bottom:                     0;

$navbar-default-bg:                        #f8f8f8;
$navbar-default-border:                    darken($navbar-default-bg, 6.5%);

// Navbar links
$navbar-default-link-color:                #777;
$navbar-default-link-hover-color:          #333;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         #555;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%);

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd;
$navbar-default-toggle-icon-bar-bg:        #888;
$navbar-default-toggle-border-color:       #ddd;


//== Breadcrumbs
//
//##
$breadcrumb-padding-vertical:   14px;
$breadcrumb-padding-horizontal: 0px;
//** Breadcrumb background color
$breadcrumb-bg:                 transparent;
//** Breadcrumb text color
$breadcrumb-color:              #fff;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       #ccc;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:          "/";